// Colors

$bg-color: rgb(9, 3, 15);
$primary-color: #FF5001;
$secondary-color: #606060;
$third-color:#a8a4a4;
$text-color: #FBFBFB;


/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&family=Syne:wght@400;500;600;700;800&display=swap');


$font-family-body: 'Poppins', sans-serif;
$font-family-titles: 'Syne', sans-serif;


