@use './abstracts/' as *;
@use './components/' as c;

body{
  background-color: $bg-color;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: auto;
}

a{
  color:$text-color;
  
  &:hover{
    color:$primary-color;
  }
}

 hr{
  height: 2px;
  border:none;
  background-color: $primary-color;
 }
 
 .myhero{
  position: relative;
  width: 100vw;
  height: auto;
  overflow: hidden;

 
 }

 .myhero img {
  width: 100%;
  height: auto;
}

.hero-container {
  position: relative;
}

.hero-container img {
  width: 100vw;
  height: auto;
  display: block;
}

.main-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  //max-width: 1440px;
 //padding: 0.6rem;
  z-index: 1; /* Coloca el main-container encima de la imagen */
}


.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0); 
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; 
  width: 1px;
}