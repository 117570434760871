@use '../abstracts/' as *;

.hero{
 max-width:55.6875rem;
 margin-left:11%; //11.25rem;//6.25rem;
 margin-top: 9rem;//11.25rem; 
 margin-bottom: 7rem;

 @media(max-width:768px){
  margin-inline: 0.8rem;
 }
}

.hero__btn{
  display: flex;
  justify-content: center;
  padding-top: 4rem;
}

.about{
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  gap:4rem;
  padding-inline: .8rem;
}
.about__card{
  //padding-left: 1rem;
  //padding-right: 1rem;
  display:flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 39.0625rem;
}
.about__card >h3{
  text-align: left;
}
.about__title{
margin-top: 8.12rem;
//width: 32.25rem;

}
.about__description{
 //color: $secondary-color;
 color: $third-color;
 margin-top: 1.3rem;
 font-size: 1.125rem;
 font-weight: 400;
 line-height: 154.5%;
 letter-spacing: 0.06188rem;
 //width: 39.0625rem;
}
.about-img{
  width: 28.375rem;
  margin-top:4.38rem;
  //height: 31.625rem;
  //background: pink;
  //border-radius: 8px;
  position: relative;
  
  & img{
    border-radius: 8px;
  }
}

//**** SHINE ******

.shine {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.shine__fill{
  fill: rgba(255, 255, 255, 0.836);
  filter: blur(100px);
}
.shine__height {
  height: 100%;
}

// *********** Rectangulo ******* ///
.rectangulo{
  border: 1px solid $primary-color;
  border-radius: 1.875rem;
}

.rectangulo--position-vertical{
  position: absolute;
  bottom:50px;
  left: -30px;
  width: 3.4375rem;
  height: 11.125rem;
}

.rectangulo--position-horizontal{
  position: absolute;
  top:-18px;
  right: 0px;
  width: 6.875rem;
  height: 2.125rem;
}

.stack{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 144px;
}

.stack_projects{
  margin-top: 200px;

  @media (max-width:375px) {
    margin-top: 100px;
  }
}

.stack_container{
  display:flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 5.625rem;
  padding: 30px 40px;
  max-width: 60%;//80
  gap:20px; //50

  @media (max-width:425px){
    max-width: 90%;//80
  }

}

.stack_container > img{
  margin: 10px 12px;
  width: 80px !important; //100

  @media (max-width:425px){
    width: 50px !important;
  }
}

//*** Card **
.card__container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  //gap: 1rem;
  margin-block: 4.5rem;
 // margin-inline: 125px;
  width: 80%;

  @media (max-width:450px){
    width: 90%;
  }
}

.card{
  //width: 35rem;
  //height: 48rem;
  display: flex;
  flex-direction: column;
  gap:2em;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  margin-block: 3rem; 
  border: 1px dotted rgb(43, 14, 71);;
}
.card__img{
 // height: 38.75rem;
  //width: 35rem;
  max-width: 35rem;
}
.card__img >img{
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.card__content{
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap:1rem;
  padding-left: .5rem;  
}

.card__details{
  font-size: 1.125rem;
  display: flex;
  flex-direction: column;
  gap:1rem;
  padding-bottom: 1rem;
}

.altura__2{
  z-index: 2;
}