@use './variables' as *;


html{
  font-family: $font-family-body;
  color: $text-color;
  scroll-behavior: smooth;
}

h1,h2,h3,h4,h5,h6{
  font-family: $font-family-titles;
  font-weight: 700;
  line-height: normal;
}

h1{
  //font-size: 5.125rem;
  font-size: clamp(4.125rem, 5vw, 5.125rem);
}


h2{
  font-size: clamp(3.4rem, 5vw, 4rem);
  text-align: center;
  //font-size: 4rem;
}
h3{
  font-size: 3.5rem;
  text-align: center;
}
h4{
  font-size: 1.5rem;
  line-height: 2rem;

  &:hover{
    color:$primary-color;
    cursor: pointer;
  }
  
}


.brand__name--footer{
  font-size: 6rem;
}

.brand__name--footer--titles{
  line-height: 2.2rem;

  &:hover{
    color: unset;
    cursor: unset;
  }
}

.hero__uppertxt{
  font-size: 1.25rem;
  font-weight: 600;
}

.hero__subtxt{
  //font-size: 2.25rem;
  font-size: clamp(1.875rem, 5vw, 2.25rem);
  font-weight: 400;
}

.hero__complementary{
  font-family: $font-family-titles;
  //font-size: 2.25rem;
  font-size: clamp(1.875rem, 5vw, 2.25rem);
  font-weight: 700;
  text-align: right;
  padding-top: 0.69rem;
  //margin-right: 5rem;

  @media (min-width: 460px) {
    margin-right: 5rem;
  }
}

.accent__colortxt{
  color: $primary-color
}
.complementary__colortxt{
  color: $bg-color;
  //color:pink;
}

.second__colortxt{
  color: $third-color;
}



.line-1{
  position: relative;
  //top: 50%;  
  width: 9em;
 // margin: 0 auto;
  border-right: 6px solid $primary-color;
  //font-size: 180%;
  
  white-space: nowrap;
  overflow: hidden;
  //transform: translateY(-50%);    
}

/* Animation */
.anim-typewriter{
animation: typewriter 4.6s steps(18) infinite,
           blinkTextCursor 500ms steps(44) infinite;
}
@keyframes typewriter {
  0% { width: 0; }
  20% { width: 0; } /* Pause at the beginning */
  40% { width: 9em; }
  60% { width: 9em; } /* Pause after typing */
  100% { width: 0; }
}

@keyframes blinkTextCursor {
  from { border-right-color: $primary-color; }
  to { border-right-color: transparent; }
}
/*
.anim-typewriter{
  animation: typewriter 1s steps(60) 1s 1 normal both,
             blinkTextCursor 500ms steps(44) infinite normal;
  }
  @keyframes typewriter{
  from{width: 0;}
  to{width: 9em;}
  }
  @keyframes blinkTextCursor{
  from{border-right-color: $primary-color;}
  to{border-right-color: transparent;}
  }*/